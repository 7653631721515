import React from 'react'
import img from '../../images/113.png'
import img1 from '../../images/quarter-of-an-hour-svgrepo-com.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { Url } from '../../../context/types/url'

const Card = ({ item, id }) => {
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.data);
    const cart = useSelector(state => state.cart)
    const categoriItem = data.filter((e) => e.categori_id == item)
    const menu = id ? item == 0 ? data.filter((e) => e.lokanta_id == 3 ? e.lokanta_id == 3 : e.lokanta_id == id) : categoriItem.filter((e) => e.lokanta_id == 3 ? e.lokanta_id == 3 : e.lokanta_id == id) : item == 0 ? data : categoriItem;





    return (
        <div className="row">
            <div className="col-12">
                {/* tab */}
                <div className="tab-content" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="nav-fruitsandveg"
                        role="tabpanel"
                        aria-labelledby="nav-fruitsandveg-tab"
                        tabIndex={0}
                    >
                        {/* row */}
                        <div className="row row-cols-2 row-cols-xl-5 row-cols-md-3 g-4">
                            {menu.map((e) => {
                                const itemInCart = cart.find((item) => item.id === e.id);
                                return (
                                    <div key={e.id} className="col ">
                                        <div className="card  card-product-v2 h-100">
                                            <div className="card-body position-relative">
                                                <div className="text-center position-relative ">
                                                    {itemInCart ? (
                                                        <>
                                                            <div className=" position-absolute top-0 start-0 ">
                                                                <span className="badge bg-danger">{itemInCart.count}</span>
                                                            </div>
                                                            <div className=" position-absolute top-0 end-0 ">
                                                                <span
                                                                    className="badge bg-danger"
                                                                    onClick={() => dispatch({ type: 'remove', id: e.id })}
                                                                    style={{ cursor: 'pointer' }}
                                                                >
                                                                    x
                                                                </span>
                                                            </div>
                                                        </>
                                                    ) : null}
                                                    <>
                                                        <img
                                                            src={`${Url}/product_imgs/${e.product_photo}`}
                                                            alt="Grocery Ecommerce Template"
                                                            className="mb-3 img-fluid"
                                                        />
                                                    </>
                                                </div>
                                                <h2 className="fs-6">{e.title_ar}</h2>
                                                <div className="d-flex justify-content-between align-items-center mt-3">
                                                    <div>
                                                        <span className="text-danger">
                                                            ₺{e.in_discount === 1 ? e.discount_price : e.price}
                                                        </span>{" "}
                                                        <span className="text-decoration-line-through text-muted">
                                                            {e.in_discount === 1 ? e.price : null}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        <span className="text-uppercase small text-danger">
                                                            <img className="img-fluid" width={15} src={img1} /> {e.time}
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="border-info">
                                                    <div className="d-grid mt-4">
                                                        <button
                                                            onClick={() => dispatch({ type: 'add', id: e.id })}
                                                            className="btn btn-danger rounded-pill"
                                                        >
                                                            إضافة
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="product-content-fade border-info" />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card