import axios from "axios";
import { Url } from "../types/url";
const apiKey = "ABC123";
export const addItem = (item) => {
      return async (dispatch) => {
            dispatch({ type: 'ADD_ITEM_START' });

            let config = {
                  method: 'post',
                  maxBodyLength: Infinity,
                  url: Url + '/api/order',
                  headers: {
                        'Content-Type': 'text/plain'
                  },
                  maxRedirects: 0,
                  data: JSON.stringify(item)
            };

            try {
                  const response = await axios.request(config);
                  dispatch({ type: 'ADD_ITEM_SUCCESS', payload: response.data });
                  return response;
            } catch (error) {
                  dispatch({ type: 'ADD_ITEM_ERROR', payload: error.message });
                  throw error;
            }
      };
};



