import React, { useState } from "react";
import QrCard from "./QrCard";
import { Link } from "react-router-dom";
import imgicon from "../../images/Spinner-1.4s-200px.svg";
import logo from "../../images/bloudan_logo.png";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const QrMenu = () => {
  const [t, i18n] = useTranslation("global");
  const handleLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };
  const { data, loading, error } = useSelector((state) => state.data);
  const All = [
    {
      categori_id: 0,
      categori: {
        categori_name_ar: "الكل",
        categori_name_tr: "Tüm",
      },
    },
  ];
  const menu = [...All, ...data];
  const [item, setItem] = useState(0);

  const uniqueCategories = new Set();

  if (loading) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <img src={imgicon} />
      </div>
    );
  }
  if (error) {
    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <span>{error}</span>
      </div>
    );
  }
  return (
    <>
      {/* navigation */}
      <div className="border-bottom shadow-sm">
        <nav className="navbar navbar-light py-2">
          <div className="container justify-content-center justify-content-lg-between">
            <Link className="navbar-brand">
              <img
                src={logo}
                width={250}
                alt=""
                className="d-inline-block align-text-top"
              />
            </Link>
          </div>
          <div className=" justify-content-end">
            <div className="btn-group">
              <button
                type="button"
                className="btn btn-danger dropdown-toggle"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {t("language")}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link
                    onClick={() => {
                      handleLanguage("ar");
                    }}
                    className="dropdown-item"
                  >
                    العربية
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      handleLanguage("tr");
                    }}
                    className="dropdown-item"
                  >
                    Türkçe
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className=" justify-content-end">
            <a
              href="https://instagram.com/bloudan_00?igshid=NzZlODBkYWE4Ng=="
              className="mx-2"
            >
              <i
                style={{ fontSize: "25px", color: "#f60672" }}
                class=" bi bi-instagram p-0"
              ></i>
            </a>
            <a
              href="https://www.facebook.com/profile.php?id=100063713343485&mibextid=LQQJ4d"
              className="mx-2"
            >
              <i
                style={{ fontSize: "25px", color: "#0878e4" }}
                class="bi bi bi-facebook p-0"
              ></i>
            </a>
            <a
              href="https://api.whatsapp.com/message/NOV3UDTYLSOPI1?autoload=1&app_absent=0"
              className="mx-2"
            >
              <i
                style={{ fontSize: "25px", color: "#2de957" }}
                class="bi bi-whatsapp p-0"
              ></i>
            </a>
          </div>
        </nav>
      </div>
      <section className="mb-lg-14 mb-8 mt-2">
        <div className="container ">
          {/* row */}
          <div className="row">
            <div className="col-lg-12">
              {/* page header */}
              <div className="card mb-4 bg-light border-0">
                {/* card body */}
                <div className="card-body p-9 text-center">
                  {/* title */}
                  <h2 className="mb-0 fs-2">{t("card.menu")} </h2>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-6 d-xl-flex justify-content-center align-items-center">
                <div>
                  {/* nav */}
                  <nav>
                    <ul
                      className="nav nav-pills nav-scroll border-bottom-0 gap-1  "
                      id="nav-tab"
                      role="tablist"
                    >
                      {/* nav item */}
                      {menu.map((e, key) => {
                        if (
                          !uniqueCategories.has(e.categori.categori_name_ar)
                        ) {
                          uniqueCategories.add(e.categori.categori_name_ar);
                          return (
                            <li key={key} className="nav-item ">
                              {/* nav link */}
                              <Link
                                onClick={() => {
                                  setItem(e.categori_id);
                                }}
                                className="nav-link"
                                id="nav-fruitsandveg-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#nav-fruitsandveg"
                                role="tab"
                                aria-controls="nav-fruitsandveg"
                                aria-selected="true"
                              >
                                {e.categori[t("card.categori")]}
                              </Link>
                            </li>
                          );
                        }
                        return null;
                      })}
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
          {/* row */}
          <QrCard item={item} />
        </div>
      </section>
    </>
  );
};

export default QrMenu;
