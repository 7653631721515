const initialAddItemState = {
      loading: false,
      item: {},
      error: null
};

export const reducerAddItem = (state = initialAddItemState, action) => {
      switch (action.type) {
            case 'ADD_ITEM_START':
                  return { ...state, loading: true, error: null };
            case 'ADD_ITEM_SUCCESS':
                  return { ...state, loading: false, item: action.payload };
            case 'ADD_ITEM_ERROR':
                  return { ...state, loading: false, error: action.payload };
            default:
                  return state;
      }
};





