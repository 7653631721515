import React from 'react'
import { Link } from 'react-router-dom'
import img from '../../images/Restaurant.jpg'
import img2 from '../../images/delivery.jpg'
import { useDispatch } from 'react-redux'
const Restaurant = () => {
    const dispatch = useDispatch();

    return (

        <section className="mt-2 mb-lg-14 mb-8">
            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item active" aria-current="page">
                            Restaurant
                        </li>
                    </ol>
                </nav>
                {/* row */}
                <div className="row">
                    {/* col */}
                    <div className="col-lg-12">
                        {/* page header */}
                        <div className="card mb-4 bg-light border-0">
                            {/* card body */}
                            <div className="card-body p-9 text-center">
                                {/* title */}
                                <h2 className="mb-0 fs-2">أختر نوع الطلب </h2>
                            </div>
                        </div>
                    </div>
                    {/* row */}
                    <div className="row ">
                        {/* col */}
                        <div className="col-6 mb-2">
                            <Link onClick={() => dispatch({ type: 'lokanta_no', id: 1 })} to="/Tables/1"
                                className="text-decoration-none text-inherit"
                            >
                                <div className="card card-product">
                                    <div className="card-body text-center py-8">
                                        <img
                                            src={img}
                                            alt="Grocery Ecommerce Template"
                                            className="mb-3 img-fluid"
                                        />
                                        <div className="text-truncate">مطعم 1</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-6 mb-2">
                            <Link onClick={() => dispatch({ type: 'lokanta_no', id: 2 })} to="/Tables/2"
                                className="text-decoration-none text-inherit"
                            >
                                <div className="card card-product">
                                    <div className="card-body text-center py-8">
                                        <img
                                            src={img}
                                            alt="Grocery Ecommerce Template"
                                            className="mb-3 img-fluid"
                                        />
                                        <div className="text-truncate">مطعم 2</div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div onClick={() => dispatch({ type: 'lokanta_no', id: 3 })} className="col-6 mb-2">
                            <Link onClick={() => dispatch({ type: 'no', id: "سفري" })} to="/Menu"
                                className="text-decoration-none text-inherit"
                            >
                                <div className="card card-product">
                                    <div className="card-body text-center py-8">
                                        <img
                                            src={img2}
                                            alt="Grocery Ecommerce Template"
                                            className="mb-3 img-fluid"
                                        />
                                        <div className="text-truncate">طلبات خارجية </div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Restaurant