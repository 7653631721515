import axios from "axios";
import { Url } from "../types/url";

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT = 'LOGOUT';

export const loginSuccess = (item) => {

      return async (dispatch) => {
            dispatch({ type: 'ADD_ITEM_START' });

            let config = {
                  method: 'post',
                  url: Url + '/api/login',
                  headers: {
                        'Content-Type': 'text/plain'
                  },
                  maxRedirects: 0,
                  data: JSON.stringify(item)
            };

            try {
                  const response = await axios.request(config);
                  dispatch({ type: LOGIN_SUCCESS, payload: response.data });
                  return response;
            } catch (error) {
                  dispatch({ type: LOGOUT, payload: error.message });
                  throw error;
            }
      };

};








