// reducerGetData.js
const initialState = {
      loading: false,
      data: [],
      error: null
    };
    
    export const reducerGetData = (state = initialState, action) => {
      switch (action.type) {
        case 'GET_DATA_START':
          return { ...state, loading: true, error: null };
        case 'GET_DATA_SUCCESS':
          return { ...state, loading: false, data: action.payload };
        case 'GET_DATA_ERROR':
          return { ...state, loading: false, error: action.payload };
        default:
          return state;
      }
    };

    const Tables = {
      loading: false,
      tables: [],
      error: null
    };
    
    export const reducerGetTables = (state = Tables, action) => {
      switch (action.type) {
        case 'GET_TABLES_START':
          return { ...state, loading: true, error: null };
        case 'GET_TABLES_SUCCESS':
          return { ...state, loading: false, tables: action.payload };
        case 'GET_TABLES_ERROR':
          return { ...state, loading: false, error: action.payload };
        default:
          return state;
      }
    };