import React, { useState } from 'react'
import Card from './Card'
import { Link, useParams } from 'react-router-dom'
import imgicon from '../../images/Spinner-1.4s-200px.svg'
import { useSelector } from 'react-redux'

const Menu = () => {
    const { id } = useParams();
    const { data, loading, error } = useSelector(state => state.data);
    const menu = id ? data.filter((e) => e.lokanta_id == 3 ? e.lokanta_id == 3 : e.lokanta_id == id) : data;
    const [item, setItem] = useState(0);


    const uniqueCategories = new Set();


    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <img src={imgicon} />
            </div>
        );
    }
    if (error) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <span>{error}</span>
            </div>
        );
    }
    return (
        <section className="mb-lg-14 mb-8 mt-2">
            <div className="container ">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/Restaurant">Restaurant</Link>
                        </li>
                        <li className="breadcrumb-item">
                            <Link to={`/Tables/${id}`}>Tables</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Menu
                        </li>
                    </ol>
                </nav>
                {/* row */}
                <div className="row">
                    <div className="col-lg-12">
                        {/* page header */}
                        <div className="card mb-4 bg-light border-0">
                            {/* card body */}
                            <div className="card-body p-9 text-center">
                                {/* title */}
                                <h2 className="mb-0 fs-2">أختر  الطلب  </h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="mb-6 d-xl-flex justify-content-center align-items-center">
                            <div>
                                {/* nav */}
                                <nav >
                                    <ul
                                        className="nav nav-pills nav-scroll border-bottom-0 gap-1  "
                                        id="nav-tab"
                                        role="tablist"
                                    >
                                        {/* nav item */}
                                        {menu.map((e) => {

                                            if (!uniqueCategories.has(e.categori.categori_name_ar)) {
                                                uniqueCategories.add(e.categori.categori_name_ar);
                                                return (

                                                    <li key={e.id} className="nav-item ">
                                                        {/* nav link */}
                                                        <Link
                                                            onClick={() => { setItem(e.categori_id) }}
                                                            className="nav-link"
                                                            id="nav-fruitsandveg-tab"
                                                            data-bs-toggle="tab"
                                                            data-bs-target="#nav-fruitsandveg"
                                                            role="tab"
                                                            aria-controls="nav-fruitsandveg"
                                                            aria-selected="true"
                                                        >
                                                            {e.categori.categori_name_ar}
                                                        </Link>
                                                    </li>
                                                )
                                            }
                                            return null;
                                        })}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                {/* row */}
                <Card item={item} id={id} />
            </div>
        </section>

    )
}

export default Menu