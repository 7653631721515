import React, { useState } from "react";
import logo from "../images/bloudan_logo.png";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { loginSuccess } from "../../context/actions/loginaction";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [responseStatus, setResponseStatus] = useState();
  const handleSubmit = async () => {
    const itemData = {
      email,
      password,
    };
    try {
      const response = await dispatch(loginSuccess(itemData));
      if (response.status === 200) {
        if (response.data.no == 3) {
          navigate("/Restaurant");
        } else {
          navigate("/Table");
        }
        setPassword("");
        setEmail("");
      } else {
        setResponseStatus("error");
      }
    } catch (error) {
      setResponseStatus("error");
    }
  };

  return (
    <>
      {/* navigation */}
      <div className="border-bottom shadow-sm">
        <nav className="navbar navbar-light py-2">
          <div className="container justify-content-center justify-content-lg-between">
            <Link className="navbar-brand">
              <img
                src={logo}
                width={300}
                alt=""
                className="d-inline-block align-text-top"
              />
            </Link>
          </div>
        </nav>
      </div>
      <main>
        {/* section */}
        <section className="my-lg-14 my-8">
          <div className="container">
            {/* row */}
            <div className="row justify-content-center align-items-center">
              <div className="col-12 col-md-6 col-lg-4 order-lg-1 order-2">
                {/* img */}
                <img
                  src="../assets/images/svg-graphics/signin-g.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              {/* col */}
              <div className="col-12 col-md-6 offset-lg-1 col-lg-4 order-lg-2 order-1">
                <div className="mb-lg-9 mb-5 text-center">
                  <h1 className="mb-1 h2 fw-bold">تسجيل الدخول </h1>
                </div>
                <form>
                  <div className="row g-3">
                    {/* row */}
                    <div dir="rtl" className="col-12">
                      {/* input */}
                      <input
                        onChange={(e) => {
                          setEmail(e.target.value);
                        }}
                        type="email"
                        value={email}
                        className="form-control"
                        id="inputEmail4"
                        placeholder="أيميل "
                        required
                      />
                    </div>
                    <div className="col-12">
                      {/* input */}
                      <div
                        dir="rtl"
                        className="password-field position-relative"
                      >
                        <input
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                          type="password"
                          value={password}
                          id="fakePassword"
                          placeholder="أدخل الرمز"
                          className="form-control"
                          required
                        />
                      </div>
                    </div>
                    {/* btn */}
                    <div className="col-12 d-grid">
                      {" "}
                      <Link onClick={handleSubmit} className="btn btn-danger">
                        تسجيل الدخول
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default Login;
