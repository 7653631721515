import React from 'react'
import img from '../../images/51882.png'
import Imgicon from '../../images/Spinner-1.4s-200px.svg'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
const Tables = () => {
    const { id } = useParams();
    const { tables, loading, error } = useSelector(state => state.tables);
    const dispatch = useDispatch();
    const masa = tables.filter((e) => e.lokanta_id == id);




    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <img src={Imgicon} />
            </div>
        );
    }

    if (error) {
        return (
            <div className="d-flex justify-content-center align-items-center vh-100">
                <span>{error}</span>
            </div>
        );
    }
    return (
        <section className="mt-2 mb-lg-14 mb-8">

            <div className="container">
                <nav aria-label="breadcrumb">
                    <ol className="breadcrumb">
                        <li className="breadcrumb-item">
                            <Link to="/Restaurant">Restaurant</Link>
                        </li>
                        <li className="breadcrumb-item active" aria-current="page">
                            Tables
                        </li>
                    </ol>
                </nav>

                {/* row */}
                <div className="row">
                    {/* col */}
                    <div className="col-lg-12">
                        {/* page header */}
                        <div className="card mb-4 bg-light border-0">
                            {/* card body */}
                            <div className="card-body p-9 text-center">
                                {/* title */}
                                <h2 className="mb-0 fs-2">أختر الطاولة  </h2>
                            </div>
                        </div>
                    </div>
                    {/* row */}
                    <div className="row ">
                        {/* col */}
                        {masa.map((e) => (
                            <div onClick={() => dispatch({ type: 'no', id: `طاوله رقم ${e.table_no} ` })} key={e.id} className="col-6 mb-2">
                                <Link to={`/Menu/${id}`}
                                    className="text-decoration-none text-inherit"
                                >
                                    <div className="card card-product">
                                        <div className="card-body text-center py-8">
                                            <img
                                                src={img}
                                                alt="Grocery Ecommerce Template"
                                                className="mb-3 img-fluid"
                                            />
                                            <div className="text-truncate">{e.table_no}</div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </section>
    )
}

export default Tables