import React, { useEffect, useState } from "react";
import img from "../images/113.png";
import logo from "../images/bloudan_logo.png";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import io from "socket.io-client";

const socket = io.connect("http://localhost:3000");
const Navbar = () => {
  const cart = useSelector((state) => state.cart);
  const { data } = useSelector((state) => state.data);
  const { tableNo } = useSelector((state) => state.tableNo);
  const { lokantaNo } = useSelector((state) => state.lokantaNo);
  const dispatch = useDispatch();
  const totalPrice = cart.reduce((total, item) => {
    const product = data.find((product) => product.id === item.id);
    return (
      total +
      [product.in_discount == 1 ? product.discount_price : product.price] *
        item.count
    );
  }, 0);
  const totalItems = cart.reduce((total, item) => total + item.count, 0);

  // socket add
  const [note, setNote] = useState("");
  const room = lokantaNo == 3 ? [1, 2] : lokantaNo;
  function generateRandomCode(length) {
    const charset = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let code = "";

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      code += charset.charAt(randomIndex);
    }

    return code;
  }
  const codeLength = 6;
  const randomCode = generateRandomCode(codeLength);

  const cartData = cart.map((e) => {
    const itemFromData = data.find((i) => i.id === e.id);
    const itemTotalPrice =
      [
        itemFromData.in_discount === 1
          ? itemFromData.discount_price
          : itemFromData.price,
      ] * e.count;

    return {
      ...itemFromData,
      totalItems: e.count,
      totalPrice: itemTotalPrice,
      tableNo: tableNo,
      note: note,
      lokantaNo: lokantaNo,
      code: randomCode,
    };
  });

  const message = cartData;
  lokantaNo == 3
    ? room.forEach((room) => {
        socket.emit("join_room", room);
      })
    : socket.emit("join_room", room);

  const sendMessage = () => {
    lokantaNo == 3
      ? room.forEach((room) => {
          socket.emit("send_message", { message, room });
        })
      : socket.emit("send_message", { message, room });

    setNote("");
    dispatch({ type: "clearCart" });
  };

  return (
    <>
      {/* navigation */}
      <header className="py-lg-5 py-4 border-bottom border-bottom-lg-0">
        <div className="container">
          <div className="row w-100 align-items-center gx-3">
            <div className="col-xl-7 col-lg-8">
              <div className="d-flex align-items-center">
                <Link className="navbar-brand d-none d-lg-block">
                  <img
                    src={logo}
                    alt="eCommerce HTML Template"
                    className="img-fluid"
                    style={{ width: 116, height: "atue" }}
                  />
                </Link>
              </div>
              <div className="d-flex justify-content-between align-items-center w-100 d-lg-none">
                <Link className="navbar-brand mb-0">
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="eCommerce HTML Template"
                    style={{ width: 160, height: "atue" }}
                  />
                </Link>
                <div className="d-flex align-items-center lh-1">
                  <div className="list-inline me-4">
                    <div className="list-inline-item ">
                      <div className="dropdown  ">
                        <Link
                          type="button"
                          id="dropdownMenuClickableInside"
                          data-bs-toggle="dropdown"
                          data-bs-auto-close="outside"
                          aria-expanded="false"
                        >
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={16}
                              height={16}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-shopping-cart align-text-bottom"
                            >
                              <circle cx={9} cy={21} r={1} />
                              <circle cx={20} cy={21} r={1} />
                              <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
                            </svg>
                          </span>
                          <span className="m-1">₺{totalPrice.toFixed(2)}</span>
                        </Link>
                        <div
                          className="dropdown-menu dropdown-menu-end dropdown-menu-lg p-5"
                          aria-labelledby="dropdownMenuClickableInside"
                        >
                          <div className="d-flex justify-content-between align-items-center border-bottom pb-5 mb-3">
                            <div>
                              <span>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width={16}
                                  height={16}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth={2}
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-shopping-cart align-text-bottom"
                                >
                                  <circle cx={9} cy={21} r={1} />
                                  <circle cx={20} cy={21} r={1} />
                                  <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6" />
                                </svg>
                              </span>
                              <span className="text-danger m-1">
                                {totalItems}
                              </span>
                            </div>
                            <div>
                              <span>Total:</span>
                              <span className="text-danger">
                                ₺{totalPrice.toFixed(2)}
                              </span>
                            </div>
                          </div>

                          <ul className="list-group list-group-flush">
                            {cart.map((e) => {
                              const itemInCart = data.find(
                                (item) => item.id === e.id
                              );

                              return (
                                <li
                                  key={itemInCart.id}
                                  className="list-group-item px-0 py-3"
                                >
                                  <div className="row align-items-center g-0">
                                    <div className="col-lg-3 col-3 text-center">
                                      {/* img */}{" "}
                                      <img
                                        src={img}
                                        alt="Ecommerce"
                                        className="icon-xxl"
                                      />
                                    </div>
                                    <div className="col-lg-7 col-7">
                                      {/* title */}
                                      <Link className="text-inherit">
                                        <h6 className="mb-0">
                                          {itemInCart.title_ar}
                                        </h6>
                                      </Link>
                                      <div className="text-center">
                                        <small className="text-muted ">
                                          {e.count} x {itemInCart.price}
                                        </small>
                                      </div>
                                    </div>
                                    {/* price */}
                                    <div className="text-end col-lg-2 col-2">
                                      <Link
                                        className="text-inherit"
                                        onClick={() =>
                                          dispatch({ type: "remove", id: e.id })
                                        }
                                        style={{ cursor: "pointer" }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width={14}
                                          height={14}
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="currentColor"
                                          strokeWidth={2}
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          className="feather feather-trash-2 text-danger"
                                        >
                                          <polyline points="3 6 5 6 21 6" />
                                          <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                          <line
                                            x1={10}
                                            y1={11}
                                            x2={10}
                                            y2={17}
                                          />
                                          <line
                                            x1={14}
                                            y1={11}
                                            x2={14}
                                            y2={17}
                                          />
                                        </svg>
                                      </Link>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                          <div className="mt-2 d-grid">
                            <Link
                              onClick={sendMessage}
                              className="btn btn-danger"
                            >
                              send
                            </Link>
                            <Link
                              type="button"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal"
                              data-bs-whatever="@mdo"
                              className="btn btn-light mt-2"
                            >
                              note
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <nav
        className="navbar navbar-expand-lg navbar-light navbar-default py-0 py-lg-2 border-top navbar-offcanvas-color "
        aria-label="Offcanvas navbar large"
      ></nav>
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                إضافة ملاحظة
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="mb-3">
                  <label htmlFor="message-text" className="col-form-label">
                    الملاحظة:
                  </label>
                  <textarea
                    onChange={(e) => {
                      setNote(e.target.value);
                    }}
                    className="form-control"
                    id="message-text"
                    defaultValue={""}
                  />
                </div>
              </form>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
              >
                إغلاق
              </button>
              <button
                type="button"
                className="btn btn-danger"
                data-bs-dismiss="modal"
              >
                حفظ
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
