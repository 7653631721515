
const initialState = {
      lokantaNo: 0,
};

// reducer for "lokantaNo"
const LokantaNoReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'lokanta_no':
                  return {
                        ...state,
                        lokantaNo: action.id,
                  };
            default:
                  return state;
      }
};

export default LokantaNoReducer;

