import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import io from "socket.io-client";
import { addItem } from "../../../context/actions/postaction";

const socket = io.connect("http://localhost/:3000");

const Table = ({ login }) => {
  const dispatch = useDispatch();

  const [receiveMessage, setReceiveMessage] = useState([]);

  socket.emit("join_room", login);

  useEffect(() => {
    const handleReceiveMessage = (data) => {
      setReceiveMessage(data.message);
    };

    socket.on("receive_message", handleReceiveMessage);

    return () => {
      // قم بإلغاء الاشتراك عند تفريغ المكون
      socket.off("receive_message", handleReceiveMessage);
    };
  }, [socket, receiveMessage]);

  const finalTableData = receiveMessage.reduce((acc, item) => {
    return item.lokantaNo;
  }, []);
  const tableData1 = receiveMessage.filter((e) => e.lokanta_id == login);

  const tableData2 =
    finalTableData === 3
      ? login === 2
        ? []
        : receiveMessage.filter((e) => e.lokanta_id === 3)
      : receiveMessage.filter((e) => e.lokanta_id === 3);

  const newData = [...tableData1, ...tableData2];
  const [tableDataNo, setTableDataNo] = useState([]);
  const addNewValue = (newValue) => {
    if (!tableDataNo.includes(newValue)) {
      setTableDataNo((prevMessage) => prevMessage.concat(newValue));
    }
  };

  useEffect(() => {
    addNewValue(newData);
  }, [socket, receiveMessage]);

  // تجميع البيانات بناءً على رقم الطاولة مع إنشاء رمز عشوائي واحد لكل طاولة
  const tableData = tableDataNo.reduce((acc, item) => {
    const existingTable = acc.find((table) => table.code === item.code);

    if (existingTable) {
      existingTable.products.push(item);
    } else {
      acc.push({
        tableNo: item.tableNo,
        products: [item],
        code: item.code,
        note: item.note,
      });
    }
    return acc;
  }, []);

  const [detailOftableNo, setDetailOftableNo] = useState();
  const Detaiol = tableDataNo.filter((e) => e.code == detailOftableNo);
  const DeleteOrder = (no) => {
    const order = tableDataNo.filter((e) => e.code !== no);
    setTableDataNo(order);
  };

  const [readyStates, setReadyStates] = useState({});
  const [responseStatus, setResponseStatus] = useState("");
  const toggleReadyState = async (code) => {
    setReadyStates((prevState) => ({
      ...prevState,
      [code]: !prevState[code],
    }));
    const Data = tableDataNo.filter((item) => item.code === code);
    const itemData = {
      items: Data.map((item) => ({
        id: parseInt(item.id),
        piece: item.totalItems,
        total_price: item.totalPrice,
        order_code: item.code,
        table_no: item.tableNo,
        note: item.note,
        lokanta_no: item.lokantaNo,
      })),
    };
    console.log(Data);
    try {
      const response = await dispatch(addItem(itemData.items));

      if (response.status === 200) {
        setResponseStatus("success");
      } else {
        setResponseStatus("error");
      }
    } catch (error) {
      setResponseStatus("error");
    }

    // تحديث قائمة العناصر بعد إرسال البيانات
    const order = tableDataNo.filter((e) => e.code !== code);
    setTableDataNo(order);
  };

  console.log(responseStatus);

  return (
    <section className="mt-2 mb-lg-14 mb-8">
      <div className="container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Chef
            </li>
          </ol>
        </nav>
        <div className="row">
          <div className="col-lg-12">
            <div className="card mb-4 bg-light border-0">
              <div className="card-body p-9 text-center">
                <h2 className="mb-0 fs-2">الطلبات</h2>
              </div>
            </div>
          </div>
          <div className="col-lg-12">
            <div className="table-responsive">
              <table className="table text-nowrap">
                <thead className="table-light">
                  <tr>
                    <th>التفاصيل</th>
                    <th>الملاحظة</th>
                    <th>العدد</th>
                    <th>مكان الطلب </th>
                    <th>الحالة</th>
                    <th>حذف </th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((table, key) => {
                    return (
                      <tr key={key}>
                        <td className="align-middle">
                          <button
                            type="button"
                            className="btn btn-soft-info mb-2"
                            data-bs-toggle="modal"
                            data-bs-target=".gd-example-modal-xl"
                            onClick={() => {
                              setDetailOftableNo(table.code);
                            }}
                          >
                            تفاصيل
                          </button>
                        </td>
                        <td className="align-middle">{table.note}</td>
                        <td className="align-middle">
                          {table.products.reduce(
                            (total, product) => total + product.totalItems,
                            0
                          )}
                        </td>
                        <td className="align-middle">{table.tableNo}</td>
                        <td className="align-middle">
                          {readyStates[table.tableNo] === undefined ||
                          readyStates[table.tableNo] === false ? (
                            <button
                              onClick={() => toggleReadyState(table.code)}
                              className="btn btn-soft-danger mb-2"
                            >
                              غير مجهز
                            </button>
                          ) : (
                            <button
                              onClick={() => toggleReadyState(table.code)}
                              className="btn btn-soft-primary mb-2"
                            >
                              جاهز
                            </button>
                          )}
                        </td>
                        <td className="align-middle">
                          <Link
                            onClick={() => {
                              DeleteOrder(table.code);
                            }}
                            className="text-inherit"
                            aria-label="Close"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={14}
                              height={14}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2 text-danger"
                            >
                              <polyline points="3 6 5 6 21 6" />
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1={10} y1={11} x2={10} y2={17} />
                              <line x1={14} y1={11} x2={14} y2={17} />
                            </svg>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <div
        className="modal fade gd-example-modal-xl"
        tabIndex={-1}
        role="dialog"
        aria-labelledby="myExtraLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                تفاصيل الطلب
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="table-responsive">
                <table className="table text-nowrap">
                  <thead className="table-light">
                    <tr>
                      <th>الاسم </th>
                      <th>العدد</th>
                      <th>كود الوجبة</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Detaiol.map((e) => {
                      return (
                        <tr key={e.id}>
                          <td className="align-middle">{e.title_ar}</td>
                          <td className="align-middle">{e.totalItems}</td>
                          <td className="align-middle">{e.product_code}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Table;
