


const initialState = {
      tableNo: 0,
};

// reducer for "tableNo"
const tableNoReducer = (state = initialState, action) => {
      switch (action.type) {
            case 'no':
                  return {
                        ...state,
                        tableNo: action.id,
                  };
            default:
                  return state;
      }
};

export default tableNoReducer;

