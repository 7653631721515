import React from "react";
import img1 from "../../images/quarter-of-an-hour-svgrepo-com.svg";
import { useDispatch, useSelector } from "react-redux";
import { Url } from "../../../context/types/url";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const QrCard = ({ item }) => {
  const [t] = useTranslation("global");
  const { data } = useSelector((state) => state.data);
  const categoriItem = data.filter((e) => e.categori_id == item);
  const menu = item == 0 ? data : categoriItem;
  console.log(data);
  return (
    <div className="row">
      <div className="col-12">
        {/* tab */}
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-fruitsandveg"
            role="tabpanel"
            aria-labelledby="nav-fruitsandveg-tab"
            tabIndex={0}
          >
            {/* row */}
            <div className="row row-cols-2 row-cols-xl-5 row-cols-md-3 g-4">
              {menu.map((e, key) => {
                return (
                  <div key={key}>
                    <Link
                      className="btn-action"
                      data-bs-toggle="modal"
                      data-bs-target={`#quickViewModal${e.id}`}
                    >
                      <div className="col ">
                        <div className="card  card-product-v2 h-100">
                          <div className="card-body position-relative">
                            <div className="text-center position-relative ">
                              <div>
                                <img
                                  src={`${Url}/product_imgs/${e.product_photo}`}
                                  alt="Grocery Ecommerce Template"
                                  className="mb-3 img-fluid"
                                />
                              </div>
                            </div>
                            <h2 className="fs-6">{e[t("card.title")]}</h2>
                            <div className="d-flex justify-content-between align-items-center mt-3">
                              <div>
                                <span className="text-danger">
                                  ₺
                                  {e.in_discount === 1
                                    ? e.discount_price
                                    : e.price}
                                </span>{" "}
                                <span className="text-decoration-line-through text-muted">
                                  {e.in_discount === 1 ? e.price : null}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="product-content-fade border-info" />
                        </div>
                      </div>
                    </Link>

                    <div
                      className="modal fade"
                      id={`quickViewModal${e.id}`}
                      tabIndex={-1}
                      aria-hidden="true"
                    >
                      <div className="modal-dialog modal-xl modal-dialog-centered">
                        <div className="modal-content">
                          <div className="modal-body p-8">
                            <div className="position-absolute top-0 end-0 me-3 mt-3">
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              />
                            </div>
                            <div className="row">
                              <div className="col-lg-6">
                                {/* img slide */}
                                <div
                                  className="product productModal"
                                  id="productModal"
                                >
                                  <div className="zoom">
                                    {/* img */}
                                    <img
                                      src={`${Url}/product_imgs/${e.product_photo}`}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="ps-lg-8 mt-6 mt-lg-0">
                                  <Link className="mb-4 d-block">
                                    {e.categori[t("card.categori")]}
                                  </Link>
                                  <h2 className="mb-1 h1">
                                    {e[t("card.title")]}
                                  </h2>
                                  <div className="fs-4 mt-2">
                                    <span className="fw-bold text-dark">
                                      ₺
                                      {e.in_discount === 1
                                        ? e.discount_price
                                        : e.price}
                                    </span>
                                    <span className="text-decoration-line-through text-muted">
                                      {e.in_discount === 1 ? e.price : null}
                                    </span>
                                  </div>
                                  <hr className="my-6" />
                                  <div>
                                    <h5
                                      dangerouslySetInnerHTML={{
                                        __html: e[t("card.description")],
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QrCard;
