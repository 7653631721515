import React, { useEffect } from "react";
import Navbar from "./components/layouts/navbar";
import Restaurant from "./components/pages/waiter/Restaurant";
import "./index.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Menu from "./components/pages/waiter/Menu";
import Tables from "./components/pages/waiter/Tables";
import Table from "./components/pages/chef/Table";
import { useDispatch, useSelector } from "react-redux";
import { getData, getTables } from "./context/actions/getaction";
import Login from "./components/pages/Login";
import QrMenu from "./components/pages/QR_menu/QrMenu";
function App() {
  const login = useSelector((state) => state.login);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getData());
    dispatch(getTables());
  }, [dispatch]);
  return (
    <div className="font">
      <BrowserRouter>
        {login.isloggedin == true ? (
          <>
            <Navbar login={login} />
            <Routes>
              {login.no == 3 ? (
                <>
                  <Route path="/Restaurant" element={<Restaurant />} />
                  <Route path="/Menu/:id" element={<Menu />} />
                  <Route path="/Menu" element={<Menu />} />
                  <Route path="/Tables/:id" element={<Tables />} />
                  <Route path="/" element={<QrMenu />} />
                </>
              ) : (
                <>
                  <Route path="/Table" element={<Table login={login.no} />} />
                  <Route path="/" element={<QrMenu />} />
                </>
              )}
            </Routes>
          </>
        ) : (
          <Routes>
            <Route path="/*" element={<Login />} />
            <Route path="/" element={<QrMenu />} />
          </Routes>
        )}
      </BrowserRouter>
    </div>
  );
}

export default App;
