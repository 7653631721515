import { LOGIN_SUCCESS, LOGOUT } from '../actions/loginaction';

const initialState = {
      
};

const loginReducer = (state = initialState, action) => {
      switch (action.type) {

            case LOGIN_SUCCESS:
                  return action.payload ;

            case LOGOUT:
                  return {
                        ...state,
                        isLoggedIn: false,
                  };
            default:
                  return state;
      }
};

export default loginReducer;
