// actions.js
import axios from "axios";
import { Url } from "../types/url";

export const getData = () => {
  return async (dispatch) => {
    dispatch({ type: 'GET_DATA_START' });
    try {
      const res = await axios.get(Url+'/api/index');
      dispatch({ type: 'GET_DATA_SUCCESS', payload: res.data});
      
    } catch (error) {
      dispatch({ type: 'GET_DATA_ERROR', payload: error.message });
    }
  };
};


export const getTables = () => {
  return async (dispatch) => {
    dispatch({ type: 'GET_TABLES_START' });
    try {
      const res = await axios.get(Url+'/api/tables');
      dispatch({ type: 'GET_TABLES_SUCCESS', payload: res.data});
      
    } catch (error) {
      dispatch({ type: 'GET_TABLES_ERROR', payload: error.message });
    }
  };
};


