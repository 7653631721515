import { createStore, applyMiddleware, combineReducers } from "redux";
import thunk from "redux-thunk";
import cartReducer from "../reducer/AddToCart";
import { reducerGetData, reducerGetTables } from '../reducer/reducerGetData';
import { reducerAddItem } from "../reducer/reducerPostData";
import tableNoReducer from "../reducer/TableNo";
import loginReducer from "../reducer/loginReducer";
import LokantaNoReducer from "../reducer/LokantaNo";


const rootReducer = combineReducers({
  cart: cartReducer,
  data: reducerGetData,
  tables: reducerGetTables,
  post: reducerAddItem,
  tableNo: tableNoReducer,
  lokantaNo: LokantaNoReducer,
  login: loginReducer

});

const store = createStore(rootReducer, applyMiddleware(thunk));


export default store;
